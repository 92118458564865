import React from "react";
import { Col, Row } from "react-bootstrap";
import logoWhite from '../../imgs/white-logo.svg';
import Unity from '../../imgs/Unity.svg';
import Service from '../../imgs/Brand image.svg'
import Line from '../../imgs/Line 1.svg'
import { useMediaQuery } from 'react-responsive'

export default function ServiceSection(){
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' })

  return(
    <Row className="mt-5" style={{border: '1px solid rgb(206, 144, 52, 0.2)', borderRadius: '40px'}}>
      <Col xs='12' className="service-main mb-5">
        <img src={logoWhite} />
        <div className="first-line animatable slideInUp ">Experience the thrill of</div>
        <div className="second-line animatable slideInUp animationDelay100">competitive gaming with</div>
        <div className="third-line animatable slideInUp animationDelay200">BeeGames gambling services!</div>
        <div className="fourth-line animatable slideInUp animationDelay300">Whether you're looking to integrate your games into our platform or choose from our pre-built white-label games to improve your platform right away, we provide two cutting-edge services that can help elevate your ideas. </div>
        <div className="fifth-line animatable slideInUp animationDelay400">Join us and revolutionize the gambling industry today!</div>
      </Col>
      <Col xl='6'  style={{position: 'relative', display: 'flex', flexDirection: 'column'}}>
        <Row className="service-detail">
          <Col sm='3' style={{textAlign: isMobile ? 'center': 'end'}}>
            <img src={Unity} style={{height: '81px'}}/>
          </Col>
          <Col sm='7' className="service-header">
            <div className="font-gotham-bold">UNITY SDK</div>
            <div className="font-gotham-light">SERVICE</div>
          </Col>
        </Row>
        <Row className="service-detail-point mt-4">
          <Col sm='3'></Col>
          <Col sm='7' className="font-gotham-light animatable slideInUp">
          <span className="font-gotham-bold">Free Assets:</span> Access a variety of free assets to facilitate your game development process. 
          </Col>
        </Row>
        <Row className="service-detail-point mt-4">
          <Col sm='3'></Col>
          <Col sm='7' className="font-gotham-light animatable slideInUp animationDelay100">
          <span className="font-gotham-bold">Premium Assets:</span> Unlock exclusive premium assets to take your games to the next level. 
          </Col>
        </Row>
        <Row className="service-detail-point mt-5 mb-3" style={{flexGrow: 1, alignItems: 'flex-end', }}>
          <Col sm='3'></Col>
          <Col sm='7'>
            <button style={{borderRadius: '10px', fontFamily: 'GothamLightItalic', paddingLeft: '40px', paddingRight: '40px'}} className="theme-btn font-gotham-italic">Learn More</button>
          </Col>
        </Row>
      </Col>
      <Col xl='6'  style={{position: 'relative', display: 'flex', flexDirection: 'column'}}>
        <img src={Line} style={{position: 'absolute', left: '-25px'}} />
        <Row  className="service-detail">
          <Col sm='3' style={{textAlign: isMobile ? 'center': 'end'}}>
            <img src={Service} />
          </Col>
          <Col sm='7' className="service-header">
            <div className="font-gotham-bold">WHITE-LABEL</div>
            <div className="font-gotham-light">SERVICE</div>
          </Col>
        </Row>
        <Row className="service-detail-point mt-4">
          <Col sm='3'></Col>
          <Col sm='7' className="font-gotham-light animatable slideInUp">
          <span className="font-gotham-bold  ">Unity Games:</span> Choose from a variety of Unity-powered games, ready to be seamlessly integrated into your platform. 
          </Col>
        </Row>
        <Row className="service-detail-point mt-4">
          <Col sm='3'></Col>
          <Col sm='7' className="font-gotham-light animatable slideInUp animationDelay100">
          <span className="font-gotham-bold ">Web Games:</span> Access our collection of high-quality web games, perfect for expanding your gaming offerings.
          </Col>
        </Row>
        <Row className="service-detail-point mt-5 mb-3" style={{flexGrow: 1, alignItems: 'flex-end', }}>
          <Col sm='3'></Col>
          <Col sm='7'>
            <button style={{borderRadius: '10px', fontFamily: 'GothamLightItalic', paddingLeft: '40px', paddingRight: '40px'}} className="theme-btn font-gotham-italic">Learn More</button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}