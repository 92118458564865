import React, { useState } from "react";
import { Row } from "react-bootstrap";
// import dp from '../../imgs/dp.png'
import emailIco from '../../imgs/emailIco.svg'
// import twitterIco from '../../imgs/twitterIco.svg'
import twitterIco from '../../imgs/connect-twitter.png'
import telegramIco from '../../imgs/telegramIco.svg'
import discordIco from '../../imgs/connect-discord.png'
import diamondIco from '../../imgs/diamondIco.svg'
import dp from '../../imgs/pfp.png'
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../App";
import { updateUser } from "../../redux/ActionCreators";

export default function SettingsSection(){
  const authedUser = useSelector(s => s.authedUser);
  const dispatch = useDispatch();
  const setUsername = () => {
    try {
      let uname = document.getElementById('username-edit').value;
      if (uname){
        if (uname !== authedUser.authedUser.username){
            // setLoading(true);
            fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/set/username`, {
                method: 'PUT',
                body: JSON.stringify({username: uname}),
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': `application/json`
                }
            }).then(r => r.json())
            .then(res => {
                if (res.success){
                    dispatch(updateUser({...authedUser.authedUser, username:res.user.username}));
                    showToast('Success')
                }
                else{
                    showToast(res.msg);
                }
            }).catch((err)=>{
                showToast("Something went wrong")
            })
        }
      }
    } catch (error) {
      console.log(error);
      showToast("Something went wrong")
    }
  }

  const fileHandler = (e) => {
    try {
      if (e.target.files.length > 0){
        console.log(e.target.files);
        console.log(e.target.files[0].size );
        console.log();
        if(e.target.files[0].size > 5000000){
            showToast("Image must be less than 5 mb")
            return
        }

        let formdata = new FormData();
        formdata.append("image", e.target.files[0], e.target.files[0].name);
        fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/set/profilepic`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            body: formdata
        }).then(r => r.json())
        .then(res => {
          if (res.success){
            dispatch(updateUser({...authedUser?.authedUser,profilepic:res.user.profilepic}  ));
            showToast("Profile Updated")
          }
        })
      }
    } catch (error) {
      console.log(error);
      showToast("Something went wrong")
    }
  }

  return(
    <>
      <div className="settingPage">
        <div className="idRow mb-3">
          <div className="col1">
            <div className="title">BEEGAMES ID</div>
            <div className="desc">Your Beegames ID is used by players to find you through the social panels in the game.</div>
          </div>
          <div className="col2">
            <div className="r1">
              <div className="beeIdM">
                <input className="title" id="username-edit" defaultValue={authedUser?.authedUser?.username} style={{
                      background: 'none',
                      border: 'none',
                      color: 'white',
                      width: '180px'
                }}  />
                <div className="id">Beegames ID</div>
              </div>
              <div className="profileCol">
                <div className="imgDiv">
                  <img src={authedUser?.authedUser?.profilepic ? `${process.env.REACT_APP_SERVER_BASE_URL}/images/${authedUser?.authedUser?.profilepic}` : dp} alt="" />
                </div>
                <input onChange={fileHandler} type="file" accept="image/jpg, image/jpeg" style={{display: 'none'}} id="set-pfp" />
                <button className="upload" onClick={() => document.getElementById('set-pfp').click()}>Upload New Photo</button>
              </div>
            </div>
            <div className="r2">
              <button className="saveBtn" onClick={setUsername}>SAVE CHANGES</button>
              <button className="walletBtn">Create Wallet</button>
            </div>
          </div>
        </div>

        <div className="idRow mb-3">
          <div className="col1">
            <div className="title">Social Accounts</div>
            <div className="desc">This information is private and will not be shared with other players. Read the <span>beegames private policy</span> anytime!</div>
          </div>
          <div className="profileMain" style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}>
            {/* <div className="mailRow">
              <img src={emailIco} alt="" className="icon" />
              <div className="inputM">
                <label >Email Address</label>
                <input placeholder="beeeeman@hgghgh.com" type="text" />
              </div>
            </div>
              */}
            <div className="mailRow">
              <img src={twitterIco} alt="" className="icon" />
              <div className="inputM">
                <label onClick={() => {
                  if (authedUser.authedUser?.twitter){}else{
                    let state = `${authedUser.authedUser.username}-${Math.floor(Math.random() * 100)}`;
                    window.open(`https://twitter.com/i/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_TWITTER_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_TWITTER_BASE_URL}&scope=tweet.read%20users.read%20offline.access&state=${state}=&code_challenge=challenge&code_challenge_method=plain`, "_self");
                  }
                }} style={{cursor: 'pointer'}}>{authedUser.authedUser?.twitter ? authedUser.authedUser?.twitter : 'Connect Twitter Link'}</label>
                {/* <input placeholder="beeeeman@hgghgh.com" type="text" /> */}
              </div>
            </div>
            <div className="mailRow">
              <img src={discordIco} alt="" className="icon" />
              <div className="inputM">
                <label onClick={() => {
                    if (authedUser.authedUser?.discord){}
                    else{
                      window.open(
                        `https://discord.com/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&scope=identify&state=${authedUser.authedUser?._id}123321${authedUser.authedUser?.username}&redirect_uri=${process.env.REACT_APP_DISCORD_BASE_URL}&prompt=consent`,
                        "_self"
                      )
                    }
                  }
                } style={{cursor: 'pointer'}}>{authedUser.authedUser?.discord ? authedUser.authedUser?.discord : 'Connect Discord Link'}</label>
                {/* <input placeholder="beeeeman@hgghgh.com" type="text" /> */}
              </div>
            </div>
            {/*
            <div className="r2">
              <button className="saveBtn">SAVE CHANGES</button>
            </div> */}
            {/* <button>Connect Discord</button>
            <button>Connect Twitter</button> */}
          </div>
        </div>

        <div className="idRow mb-3">
          <div className="col1">
            <div className="title">Subscription</div>
            <div className="desc">Unlock instant access to all existing & upcoming products</div>
          </div>
          <div className="profileMain">
           <div className="basicM">
            <div className="basic">BASIC</div>
            <div className="basic"><img src={diamondIco} alt="" /> VIP</div>
           </div>
            <div className="r2">
              <button className="saveBtn">SAVE CHANGES</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}