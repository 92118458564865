// import { serverPath } from "../config.js";

import * as ActionTypes from './ActionTypes';
import axios from "axios";

export const loginUser = (u) => ({
  type: ActionTypes.LOGGED_IN,
  payload: u
});

export const updateUser = (u) => ({
  type: ActionTypes.UPDATE_USER,
  payload: u
});

export const logoutUser = (u) => ({
  type: ActionTypes.LOGGED_OUT,
});

export const loginAdmin = (u) => ({
  type: ActionTypes.LOGGED_IN_ADMIN,
  payload: u
});
export const logoutAdmin = (u) => ({
  type: ActionTypes.LOGGED_OUT_ADMIN,
  payload: u
});

export const setDiscordUser = (code, uid) => dispatch => {
  return fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/discord`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`
    },
    body: JSON.stringify({code, uid})
  })
    .then(res => {
      console.log(res);
      return res.json();
    })
    .then(r => {
      if (r.success){
        console.log(r);
        r.user.token = localStorage.getItem('token');
        if (r.success){
          return dispatch(loginUser(r.user));
        }
      }
      else{
        alert(`Failed: ${r.msg}`);
        return {}
      }
    });
};

export const getTwitterUser = (code, username) => dispatch => {
  return fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/edit-twitter-user`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`
    },
    body: JSON.stringify({ code, username })
  }).then(r => r.json())
  .then(res => {
    console.log(res);
    if (res.success){
      res.user.token = localStorage.getItem('token');
      if (res.success){
        return dispatch(loginUser(res.user));
      }
      else{
        alert(`Failed: ${res.err_message}`);
      }
    }
  });
}
export function setCookie(name,value,days) {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/; domain=beegames.pro";
}
export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}
export function eraseCookie(name) {   
  document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export const getUserBalance = (u) => (dispatch) => {
  return fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/get/points-userId`, {
    method:'GET',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  }).then(r => r.ok ? r.json() : ({success:false, points: 0}))
  .then((r) => {
    return dispatch(updateUser({...u, points: r?.points ? r.points.reduce((accumulator, object) => {
      return accumulator + object.points;
    }, 0) : 0}))
  })
}

// Chat actions
export const setServerMessages = (u) => ({
  type: ActionTypes.SET_SERVER_MESSAGES,
  payload: u,
});

export const addLiveMessage = (u) => ({
  type: ActionTypes.ADD_LIVE_MESSAGE,
  payload: u,
});

export const getChatNotifications = (userId) => (dispatch) => {
  axios
    .get(
      `${process.env.REACT_APP_SERVER_BASE_URL}/chat-notification/get-all-notifications?userId=${userId}`
    )
    .then((res) => {
      return dispatch({
        type: ActionTypes.SET_CHAT_NOTIFICATIONS,
        payload: res.data,
      });
    });
};
export const setContextualMessages = (messageId) => (dispatch) => {
  axios
    .get(
      `${process.env.REACT_APP_SERVER_BASE_URL}/chat/load-contextual-messages?messageId=${messageId}`
    )
    .then((res) => {
      return dispatch({
        type: ActionTypes.SET_SERVER_MESSAGES,
        payload: res.data,
      });
    });
};

export const setChatMessageFocus = (u) => ({
  type: ActionTypes.SET_CHAT_MESSAGE_FOCUS,
  payload: u,
});
