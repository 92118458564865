import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import './index.css';
import FirstSection from "./FirstSection";
import GamesSection from "./GamesSection";
import diceImg from '../../imgs/dice-card-img.png'
import diceIco from '../../imgs/diceIco.png'
import connectImg from '../../imgs/connect-card-img.png'
import connect4Ico from '../../imgs/connect4Ico.png'
import rpsImg from '../../imgs/rps-card-img.png'
import rpsIco from '../../imgs/rpsIco.png'
import comingSoon1 from '../../imgs/coming-soon-1.png'
import comingSoon2 from '../../imgs/coming-soon-2.png'
import VIPSection from "./VIPSection";
import ServiceSection from "./ServiceSection";
import TournamentSection from "./TournamentSection";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserBalance, loginUser } from "../../redux/ActionCreators";
import { animate } from "../../js/animate";
import { PartnersSection } from "./partnersSection";

const gameSections = [
  {
    sectionName: 'Classic',
    games: [
      {
        name: 'DICE',
        localName: 'dice',
        img: diceImg,
        icon: diceIco,
        players: '24.2K',
        pointsEarned: '34k',
        link: 'https://dice.beegames.pro',
        animation:true
      },
      {
        name: 'Connect4 LuckyPot',
        localName: 'connect4',
        img: connectImg,
        icon: connect4Ico,
        players: '24.2K',
        pointsEarned: '34k',
        link: 'https://connect4.beegames.pro',
        animation:true
      },
      {
        name: 'RPS',
        localName: 'rps',
        img: rpsImg,
        icon: rpsIco,
        players: '24.2K',
        pointsEarned: 'You Never Played',
        link: 'https://rps.beegames.pro',
        animation:true
      },
      {
        name: 'Coin Flip',
        img: comingSoon1,
        players: '24.2K',
      },
      {
        name: 'Othello',
        img: comingSoon2,
        players: '24.2K',
      },
      {
        name: 'Tic Tac Toe',
        img: comingSoon1,
        players: '24.2K',
      },
    ]
  },
  {    
    sectionName: 'Arcade',
    games: [
      {
        name: 'Pinball',
        img: comingSoon1,
        players: '24.2K',
      },
      {
        name: 'Tetris',
        img: comingSoon2,
        players: '24.2K',
      },
      {
        name: 'Brick Balls',
        img: comingSoon1,
        players: '24.2K',
      },
      {
        name: 'Puzzle Bubble',
        img: comingSoon2,
        players: '24.2K',
      },
      {
        name: 'Ice Table',
        img: comingSoon1,
        players: '24.2K',
      },
      {
        name: 'Marble Race',
        img: comingSoon2,
        players: '24.2K',
      },
    ]
  },
  {
    sectionName: 'Card',
    games: [
      {
        name: 'UNO',
        img: comingSoon2,
        players: '24.2K',
      },
      {
        name: "Texas hold'em",
        img: comingSoon1,
        players: '24.2K',
      },
      {
        name: 'Kemps',
        img: comingSoon2,
        players: '24.2K',
      },
      {
        name: 'Rummy',
        img: comingSoon1,
        players: '24.2K',
      },
      {
        name: 'Scopa',
        img: comingSoon2,
        players: '24.2K',
      },
      {
        name: 'Guts',
        img: comingSoon1,
        players: '24.2K',
      },
    ]
  }
]

export default function Dashboard(){

  useEffect(() => {
    animate()
  }, [])
  
  return(
    <Container fluid className="dashboardPage">
      <FirstSection />
      {gameSections.map((gameSection, i) => {
        return(
          <GamesSection gameSectionObject={gameSection} key={`${i}-${gameSection.sectionName}`} />
        );
      } )}
      <VIPSection />
      <TournamentSection/>
      <ServiceSection />
      <PartnersSection />
    </Container>
  )
}