import { useContext, useEffect, useState } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import CollapseIcon from '../../imgs/sidebar-collapse-icon.svg';
import DashboardIcon from '../../imgs/sidebar-dashboard-icon.svg';
import GamesIcon from '../../imgs/sidebar-games-icon.svg';
import TournamentIcon from '../../imgs/sidebar-tournament-icon.svg';
import LeaderboardIcon from '../../imgs/sidebar-leaderbord-icon.svg';
import VIPIcon from '../../imgs/sidebar-vip-icon.svg';
import ServicesIcon from '../../imgs/sidebar-services-icon.svg';
import SoonBtn from '../../imgs/soon-btn.svg';
import FAQIcon from '../../imgs/sidebar-faq-icon.svg';
import ReportIcon from '../../imgs/sidebar-report-icon.svg';
import './index.css';
import { CollapsedContext } from '../../context';
import SignupBtn from './signup';
import Login from './login';
import { useDispatch, useSelector } from 'react-redux';
import SidebarFriends from '../../imgs/sidebar-friends.svg'
import SidebarStats from '../../imgs/sidebar-stats.svg'
import SidebarSettings from '../../imgs/sidebar-settings.svg'
import SidebarReferrals from '../../imgs/sidebar-referral.svg'
import SidebarLogout from '../../imgs/sidebar-logout.svg'
import SidebarPfp from '../../imgs/sidebar-pfp.png';
import SidebarPro from '../../imgs/sidebar-pro-tag.svg';
import SidebarLine from '../../imgs/sidebar-bottom-line.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { eraseCookie, logoutUser } from '../../redux/ActionCreators';
import { CgEditFade } from "react-icons/cg";
import { BsFillDice6Fill } from "react-icons/bs";
import { IoDiamond } from "react-icons/io5";
import { RiScissorsCutFill } from "react-icons/ri";
import { LuBoxes } from "react-icons/lu";
import { FaVolleyball } from "react-icons/fa6";
import { MdSportsHockey } from "react-icons/md";
import { IoFootballSharp } from "react-icons/io5";
import { LuJoystick } from "react-icons/lu";
import { TbCards } from "react-icons/tb";
import { GiPokerHand,GiCard10Clubs, GiCard6Clubs, GiCardBurn, GiCoinflip } from "react-icons/gi";

export default function SidebarCustom({collapsed, setCollapsed}) {
  const [ isScrolling, setIsScrolling ] = useState(true);
  const authedUser = useSelector(state => state.authedUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const updateWindowDimensions = () => {
    const newHeight = window.innerHeight;
    console.log((newHeight - 81), document.getElementById('sidebar-inside-div').scrollHeight)
    if ((newHeight - 81) < document.getElementById('sidebar-inside-div').scrollHeight){
      setIsScrolling(true);
      return true;
    }
    else{
      setIsScrolling(false);
      return false;
    }
  };
  useEffect(() => {
    if (collapsed){
      setIsScrolling(false);
    }
    else{
      setIsScrolling(true);
    }
  }, [collapsed]);

  const logout = ()=> {
		localStorage.clear()
		eraseCookie('token');
		dispatch(logoutUser());
	}

  useEffect(() => {
    window.addEventListener("resize", updateWindowDimensions);
    updateWindowDimensions()
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);
  useEffect(() => {
    if (Object.keys(authedUser.authedUser).length > 0){
      setIsScrolling(true);
    }
  }, [authedUser.authedUser]);
  return(
    <>
    <Sidebar id='sidebar' width='320px' backgroundColor={'#181818'} collapsed={collapsed}>
      <Menu id='sidebar-inside-div'>
        <MenuItem icon={<img src={CollapseIcon} />} className='sidebar-collapse-item' rootStyles={{color: '#77797E', height: 'auto'}} onClick={() => setCollapsed(!collapsed)}> 
          Navigate
        </MenuItem>
        <MenuItem active={location.pathname == '/'? true: false} className='sidebar-item dashboard-item' onClick={() => navigate('/')} icon={<img src={DashboardIcon} />} rootStyles={{color: '#77797E'}}> Dashboard </MenuItem>
        <SubMenu dir='right'  className='sidebar-item' icon={<img src={GamesIcon} />} rootStyles={{color: '#77797E', }} label="Games" > 
        <div style={{
          background: 'black',
          margin: '5px 20px',
          padding: '20px',
          borderRadius: '16px'
        }}>
          <div className='games-names'>
            <div className='games-types'>Classic Games</div>
            <div className='games-name' onClick={() => window.open('https://dice.beegames.pro')}><span className="icon"><BsFillDice6Fill /></span>Dice</div>
            <div className='games-name' onClick={() => window.open('https://connect4.beegames.pro')}><span className="icon"><CgEditFade /></span>Connect4LuckyPot</div>
            <div className='games-name' onClick={() => window.open('https://rps.beegames.pro')}><span className="icon"><RiScissorsCutFill /></span>RPS</div>
            <div className='games-name'><span className="icon"><GiCoinflip  /></span>Coin Flip</div>
            <div className='games-name'><span className="icon"><IoDiamond /></span>Othello</div>
          </div>
          <div className='games-names'>
            <div className='games-types'>Arcade Games</div>
            <div className='games-name'><span className="icon"><CgEditFade /></span>Pinball</div>
            <div className='games-name'><span className="icon"><LuBoxes /></span>Tetris</div>
            <div className='games-name'><span className="icon"><FaVolleyball /></span>Brick Balls</div>
            <div className='games-name'><span className="icon"><LuJoystick /></span>Puzzle Bubble</div>
            <div className='games-name'><span className="icon"><MdSportsHockey /></span>Ice Table</div>
          </div>
          <div className='games-names'>
            <div className='games-types'>Card Games</div>
            <div className='games-name'><span className="icon"><TbCards /></span>UNO</div>
            <div className='games-name'><span className="icon"><GiPokerHand /></span>Texas Hold'em</div>
            <div className='games-name'><span className="icon"><GiCard10Clubs  /></span>Kemps</div>
            <div className='games-name'><span className="icon"><GiCard6Clubs  /></span>Rummy</div>
            <div className='games-name'><span className="icon"><GiCardBurn /></span>Scopa</div>
          </div>
        </div>
        </SubMenu>
        <MenuItem active={location.pathname == '/tournaments'? true: false} className='sidebar-item' icon={<img src={TournamentIcon} />} rootStyles={{color: '#77797E'}}> Tournaments <img src={SoonBtn} /> </MenuItem>
        <MenuItem active={location.pathname == '/leaderboard'? true: false} className='sidebar-item' icon={<img src={LeaderboardIcon} />} rootStyles={{color: '#77797E'}}> Leaderboard <img src={SoonBtn} /> </MenuItem>
        <MenuItem active={location.pathname == '/vip'? true: false} onClick={() => navigate('/vip')} className='sidebar-item' icon={<img src={VIPIcon} />} rootStyles={{color: '#77797E'}}> VIP </MenuItem>
        <MenuItem className='sidebar-item' icon={<img src={ServicesIcon} />} rootStyles={{color: '#77797E'}}> Services </MenuItem>
        <MenuItem className='sidebar-item text' rootStyles={{cursor: 'default', color: '#77797E'}}> Get Help </MenuItem>
        <MenuItem className='sidebar-item faq-and-report' rootStyles={{color: '#77797E'}}> 
          <div onClick={() => navigate('/faq')}>
            <img src={FAQIcon} /> <span>FAQ</span>
          </div>
          <div>
            <img src={ReportIcon} /> <span>Report</span>
          </div>
        </MenuItem>
        <div style={isScrolling ? {} : {
          position: 'absolute',
          bottom: '10px',
          width: '100%'
        }}>
          {Object.keys(authedUser.authedUser).length > 0 ? (
            <>
            <MenuItem className={collapsed?'mt-3 sidebar-item logged-in-items collapsed':'mt-3 sidebar-item logged-in-items'}  rootStyles={{backgroundColor: 'none', color: '#77797E'}}> 
              <div>
                <div className='logged-in-items-div'>
                  <div onClick={() => navigate('/user', {state: {tab: 'referrals'}})}>
                    <img src={SidebarReferrals} /> Referral
                  </div>
                  <div onClick={() => navigate('/user', {state: {tab: 'stats'}})}>
                    <img src={SidebarStats} /> Stats
                  </div>
                  <div onClick={() => navigate('/user', {state: {tab: 'friends'}})}>
                    <img src={SidebarFriends} /> Friends
                  </div>
                  <div onClick={() => navigate('/user', {state: {tab: 'settings'}})}>
                    <img src={SidebarSettings} /> Settings
                  </div>
                  <div onClick={logout}>
                    <img src={SidebarLogout} /> Logout
                  </div>
                </div>
              </div>
            </MenuItem>
            <MenuItem style={{marginTop: '35px'}} icon={<img style={{height: '50px', width: '50px', borderRadius: '50%'}} src={authedUser?.authedUser?.profilepic ? `${process.env.REACT_APP_SERVER_BASE_URL}/images/${authedUser?.authedUser?.profilepic}` : SidebarPfp} />} suffix={<img src={SidebarSettings} /> } className={collapsed?'mt-3 sidebar-item profile-pic collapsed':'mt-3 sidebar-item profile-pic'}  rootStyles={{backgroundColor: 'none', color: '#77797E'}}>
            <img style={Object.assign({
              position: 'absolute',
              top: '-18px',
              left: '0px',
            }, collapsed ? {height: '1px', width: '100px'} : {})} src={SidebarLine} />
            <span style={{cursor: 'pointer'}} onClick={() => navigate('/user')} className='mx-2'>{authedUser.authedUser.username}</span> <img  src={SidebarPro} />
            </MenuItem>
            </>
          ) : (
            <MenuItem className={collapsed?'mt-3 sidebar-item signup-and-login collapsed':'mt-3 sidebar-item signup-and-login'}  rootStyles={{color: '#77797E'}}> 
              <div>
                <SignupBtn />
                {/* <Login /> */}
                
              </div>
            </MenuItem>
          )}
        </div>
      </Menu>
    </Sidebar>
    </>
  );
}