import React from 'react';
import './index.css';
import { Col, Row } from 'react-bootstrap';
import logo from '../../imgs/logo.svg'
import stamp from '../../imgs/stamp.png'
import CEX from '../../imgs/CEX.svg'
// import Twitter from '../../imgs/twitter-white.svg'
// import Telegram from '../../imgs/telegram-white.svg'
import EthScan from '../../imgs/ethscan-white.svg'
import {ReactComponent as Twitter} from "../../imgs/twitter.svg";
import {ReactComponent as Telegram} from "../../imgs/telegram.svg";
import {ReactComponent as Social3} from "../../imgs/social3.svg";
import {ReactComponent as Social4} from "../../imgs/social4.svg";
import { Link } from 'react-router-dom';

export default function Footer() {
	const styles = {
		p: { fontSize: 13},
		h3: { fontSize: 30, fontWeight: "bold",marginLeft:60 },
		h4: { fontSize: 15, fontWeight: "bold", color: "white" },
    ul:{listStyle:'none', padding:0, fontSize:13},
	a:{color:'#a5a5a5'}
	};

	return (
		<footer className='mt-4'>
			<div className="d-flex baseF p-sm-5 p-3 footerM">
				<div className="col1">
					<img className="logo" src={logo} alt=""></img>
					<p style={styles.p}>
						The first web3 casino where you bet against your friends!
					</p>
					<h3 style={styles.h3}>+18</h3>
				</div>
                <a href="https://assuredefi.com/projects/beegames/" target="_blank" className="kycImg">
						<img className="img-fluid" src={stamp} alt="" />
				</a>
				{/* <div className="footerInner"> */}
					<div className="col2 ">
						<h4 style={styles.h4}>BEEGAMES</h4>
						<ul style={styles.ul}>
							{/* <li>News</li> */}
							<li><Link to={"/faq"} style={styles.a}>FAQ</Link></li>
							<li><a target="_blank" href="https://beegames.gitbook.io/beegames-whitepaper/" style={styles.a}>Whitepaper</a></li>
							{/* <li><a href="#" style={styles.a}>Dextools</a></li>
							<li><a href="#" style={styles.a}>Contract Address</a></li> */}
							{/* <li><a target="_blank" href="https://www.dextools.io/app/en/ether/pair-explorer/0x583d017bcda8612577f8ec4ea4cbf548269a006a?t=1711392463486" style={styles.a}>Dextools</a></li>
							<li><a target="_blank" href="https://etherscan.io/token/0xcd8c163cc0ec3a53ce6bec89e665fb97cce51c42" style={styles.a}>Contract Address</a></li> */}
							<li>Support</li>
						</ul>
					</div>
                    <div className="socialMain">
                        <a href="https://twitter.com/BeeGames" target="_blank" className="icon"><Twitter /></a>
                        <a href="https://t.me/BeeGames" target="_blank" className="icon"><Telegram /></a>
                        <a href="#" target="_blank" className="icon"><Social3 /></a>
                        <a href="#" target="_blank" className="icon"><Social4 /></a>
                    </div>
					
				{/* </div> */}
			</div>
		</footer>
	);
};